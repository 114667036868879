import { Platform } from '@angular/cdk/platform';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NotificationHeader } from '@app/models/notification-header.model';
import { NotificationObservable } from '@app/observables/notification.observable';
import { ShareInfoService } from '@app/services/share-info/share-info.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-footer',
    standalone: true,
    imports: [RouterLink],
    templateUrl: './footer.component.html',
    styleUrl: './footer.component.sass',
})
export class FooterComponent implements OnInit, OnDestroy {
    listSubscription: Subscription[] = [new Subscription()];
    notifications: number = 0;
    notificationHeader: NotificationHeader | null = null;
    private readonly _platform: Platform = inject(Platform);
    private notificationObservable: NotificationObservable = inject(
        NotificationObservable
    );

    ngOnInit(): void {
        this.subscriptionNotification();
    }

    ngOnDestroy() {
        this.listSubscription.forEach(itrSub => {
            itrSub.unsubscribe();
        });
    }

    private subscriptionNotification(): void {
        if (!this._platform.isBrowser) return;

        this.listSubscription[0] = this.notificationObservable.data$.subscribe(
            (res: NotificationHeader | null) => {
                if (!res || res.openChanges == undefined) return;

                this.notifications = res.openChanges;
                this.notificationHeader = res;
            }
        );
    }

    updateNotification(): void {
        if (!this.notificationHeader) return;

        this.notificationHeader.openChanges = 0;
        this.notificationObservable.updateData(this.notificationHeader);
    }
}
