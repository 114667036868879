import { animate, style, transition, trigger } from '@angular/animations';
import { NgIf } from '@angular/common';
import {
    Component,
    ElementRef,
    HostBinding,
    HostListener,
    inject,
    Input,
    OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { NotificationSV } from '@app/models/notification-sv.model';
import { NavigationService } from '@app/services/navigation/navigation.service';
import { ShareInfoService } from '@app/services/share-info/share-info.service';
import { ButtonComponent } from '../button/button.component';
import { Router } from '@angular/router';

@Component({
    selector: 'app-notification',
    standalone: true,
    imports: [ButtonComponent, NgIf],
    templateUrl: './notification.component.html',
    styleUrl: './notification.component.sass',
    animations: [
        trigger('slideInOut', [
            transition(':enter', [
                style({ right: '-2.5rem' }),
                animate('0.3s ease-in-out', style({ right: '20px' })),
            ]),
            transition(':leave', [
                animate('0.3s ease-in-out', style({ right: '-2.5rem' })),
            ]),
        ]),
    ],
})
export class NotificationComponent implements OnDestroy {
    @HostBinding('@slideInOut') snackbarVisible: boolean;

    @HostListener('document:click', ['$event'])
    clickOutside(event: Event) {
        // Verifica si el clic ocurrió fuera del elemento
        if (
            this.notificationVisible &&
            !this.element.nativeElement.contains(event.target)
        ) {
            this.notificationVisible = false;
            this.clearTimeout();
        }
    }

    public bellIcon: string = 'assets/img/icons/bell.svg';
    public closeIcon: string = 'assets/img/icons/cross.svg';
    public notificationVisible: boolean = false;
    mouseOverComponent: boolean = false;
    notificationSubscription: Subscription;
    timeoutId: any;

    private readonly shareInfoService = inject(ShareInfoService);
    private readonly navigationService = inject(NavigationService);

    @Input()
    notificationData: NotificationSV;

    constructor(
        private element: ElementRef,
        private router: Router
    ) {
        this.notificationData = {
            title: '',
            time: '',
            description: ' ',
        };

        this.notificationSubscription =
            this.shareInfoService.notification$.subscribe(data => {
                this.notificationData = data;
                this.notificationVisible = true;
                this.startTimeout();
            });

        if (!this.notificationData)
            throw new Error(
                'NotificationComponent: notificationData is required'
            );
    }

    ngOnDestroy(): void {
        this.notificationSubscription.unsubscribe();
        this.clearTimeout();
    }

    onMouseEnter() {
        this.mouseOverComponent = true;
        this.clearTimeout();
    }

    onMouseLeave() {
        this.mouseOverComponent = false;
        this.startTimeout();
    }

    startTimeout() {
        if (!this.mouseOverComponent) {
            this.timeoutId = setTimeout(() => {
                this.closeNotification();
            }, 7500);
        }
    }

    clearTimeout() {
        clearTimeout(this.timeoutId);
    }

    redirect(url: string) {
        this.navigationService.navigatePage(url);

        if (this.router.url.includes('chat')) {
            setTimeout(() => {
                location.reload();
            }, 100);
        }

        this.closeNotification();
    }

    closeNotification() {
        this.notificationVisible = false;
        this.notificationData = {
            title: '',
            time: '',
            description: '',
            url: '',
        };
        return;
    }
}
