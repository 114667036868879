<div id="bottom-navegation-bar">
    <nav class="content row">
        <a
            [routerLink]="['/inicio']"
            class="element col col-tablet col-phablet col-phone"
            routerLinkActive="router-link-active">
            <img alt="" src="assets/img/icons/home.png" />
            <span>inicio</span>
        </a>
        <a
            [routerLink]="['/categorias']"
            class="element col col-tablet col-phablet col-phone"
            routerLinkActive="router-link-active">
            <img alt="" src="assets/img/icons/tab-3.svg" />
            <span>buscar</span>
        </a>
        <div
            [routerLink]="['/publicacion/crear']"
            class="element col col-tablet col-phablet col-phone"
            routerLinkActive="router-link-active">
            <img alt="" src="assets/img/icons/tab-2.svg" />
            <span>publicar</span>
        </div>
        <a
            [routerLink]="['/campaigns']"
            class="element col col-tablet col-phablet col-phone"
            routerLinkActive="router-link-active">
            <img alt="" src="assets/img/icons/trophy.svg" />
            <span>campañas</span>
        </a>
        <div
            (click)="updateNotification()"
            [routerLink]="['/barter']"
            class="element col col-tablet col-phablet col-phone"
            routerLinkActive="router-link-active">
            <img alt="" src="assets/img/icons/tab-4.svg" />
            <span>tratos</span>

            @if (notifications > 0) {
                <div class="bell-notification">
                    {{ notifications }}
                </div>
            }
        </div>
    </nav>
</div>
