import { Pipe, PipeTransform } from '@angular/core';

import { environment } from '@environments/environment';

@Pipe({
    name: 'home',
    standalone: true,
})
export class HomePipe implements PipeTransform {
    transform(value: unknown, ...args: unknown[]): string {
        const data: any = value;
        if (!data) return '';

        switch (args[0]) {
            case 'starts':
                return data.user.info.numberStarts;

            case 'image-user':
            case 'image': {
                if (!data) return 'assets/img/icons/user.svg';

                return environment.URL_FILE + data.info.picture;
            }

            case 'image-avatar': {
                if (!data) return 'assets/img/icons/user.svg';

                const picture = data.info.avatar || data.info.picture;

                return environment.URL_FILE + picture;
            }

            case 'distance': {
                const distance: string =
                    data.distance != undefined
                        ? String((Number(data.distance) / 1000).toFixed(0))
                        : ' ';
                return `${distance} km de ti`;
            }
        }

        return '';
    }
}
