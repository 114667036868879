<app-snack-bar-vicente></app-snack-bar-vicente>
<app-tip></app-tip>

@if (recaptcha) {
    <router-outlet></router-outlet>
}
<app-notification></app-notification>

@if (viewLoading) {
    <app-spinner></app-spinner>
}
