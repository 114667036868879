import { NgClass, TitleCasePipe } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, inject } from '@angular/core';
import { Router } from '@angular/router';
import { viewError } from '@app/functions/view-error-user.function';
import { Category } from '@app/models/category/category.model';
import { ErrorHttp } from '@app/models/error-http.model';
import { SkeletonCategoriesCarouselComponent } from '@app/modules/search/skeletons/skeleton-categories-carousel/skeleton-categories-carousel.component';
import { CategoryService } from '@app/providers/category/category.service';
import { ShareInfoService } from '@app/services/share-info/share-info.service';
import { environment } from '@environments/environment';

import { register as registerSwiperElements } from 'swiper/element/bundle';

registerSwiperElements();

@Component({
    selector: 'app-categories-carousel',
    standalone: true,
    imports: [NgClass, SkeletonCategoriesCarouselComponent],
    templateUrl: './categories-carousel.component.html',
    styleUrl: './categories-carousel.component.sass',
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CategoriesCarouselComponent {
    public categories: Category[];
    public urlFile = environment.CATEGORY_FILE;

    public titleCase: TitleCasePipe = new TitleCasePipe();

    private readonly categoryService = inject(CategoryService);
    private readonly router = inject(Router);

    constructor(private shareInfoService: ShareInfoService) {}

    ngOnInit(): void {
        this.getCategories();
    }

    private getCategories(): void {
        this.categoryService.categoryHome().subscribe(
            ({ data }) => {
                this.categories = data;
            },
            ({ code }: ErrorHttp) => {
                viewError('error', code, this.shareInfoService);
            }
        );
    }

    public viewCategory(category: Category) {
        this.router.navigate(['/categoria/', category._id]);
    }

    public background(type: 'EXCHANGES' | 'DONATIONS'): string {
        if (type === 'EXCHANGES') {
            return 'rgba(101, 50, 143, .75)';
        }
        if (type === 'DONATIONS') {
            return 'rgba(51, 189, 181, .75)';
        }
        return 'rgba(189, 189, 189, .75)';
    }
}
