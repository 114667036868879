import { Platform } from '@angular/cdk/platform';
import { Component, inject } from '@angular/core';

@Component({
    selector: 'app-page-ip-invalid',
    standalone: true,
    imports: [],
    templateUrl: './page-ip-invalid.component.html',
    styleUrl: './page-ip-invalid.component.sass',
})
export class PageIpInvalidComponent {
    private readonly _platform = inject(Platform);

    image: string = 'assets/img/error-messages/ip_invalid_1.jpg';
    constructor() {
        if (!this._platform.isBrowser) return;
        const images: string[] = [
            'assets/img/error-messages/ip_invalid_1.jpg',
            'assets/img/error-messages/ip_invalid_2.jpg',
            'assets/img/error-messages/ip_invalid_3.jpg',
        ];

        const randomIndex = Math.floor(Math.random() * images.length);
        this.image = images[randomIndex];
    }
}
