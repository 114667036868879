<div class="skeleton-head">
    <div class="skeleton skeleton-photo"></div>
    <div class="name-content">
        <div class="skeleton skeleton-name"></div>
        <div class="skeleton skeleton-stars"></div>
    </div>
    <div class="skeleton skeleton-button"></div>
</div>
<app-publication-swiper-skeleton></app-publication-swiper-skeleton>
<div class="skeleton-link">
    <div class="skeleton skeleton-button"></div>
    <div class="skeleton skeleton-button"></div>
    <div class="skeleton skeleton-button"></div>
    <div class="skeleton skeleton-button"></div>
</div>
