<header class="purple-bg w-100" [class.hidden]="isHeaderHidden">
    <div
        class="large-container m-auto"
        [ngClass]="{ 'justify-content-center': !currentAccount }">
        @if (currentAccount) {
            <button
                class="button-menu"
                type="button"
                (click)="isHiddenMenu = !isHiddenMenu"
                (focusout)="focusOutHideMenu()">
                <img
                    class="navBarImg bg-rainbow"
                    onerror="this.onerror=null;this.src='assets/img/icons/user.svg';"
                    routerLinkActive="router-link-active"
                    [src]="
                        profilePipe.transform(
                            currentAccount?.info?.avatar ||
                                currentAccount?.info?.picture,
                            'img-empty'
                        )
                    " />
            </button>
        }

        <nav
            [ngStyle]="{ display: isHiddenMenu ? 'none' : 'flex' }"
            class="header-group-actions-account">
            <a [routerLink]="[viewUser]">Mi perfil</a>
            <a [routerLink]="['/profile/settings']">Configuración</a>
            <a (click)="launchModalLogOut()">Cerrar sesión</a>
        </nav>

        <a
            class="logo"
            [ngClass]="{ 'justify-content-center': currentAccount }"
            [routerLink]="['/inicio']">
            <img src="assets/img/logo_aniversario.png" />
        </a>

        @if (currentAccount) {
            <div (click)="viewNotification()" class="view-notification">
                <img class="notificationImg" src="assets/img/icons/bell.svg" />

                @if (notifications > 0) {
                    <div class="bell-notification">
                        {{ notifications }}
                    </div>
                }
            </div>
        } @else {
            <div class="d-flex align-items-center" (click)="toggleMobileMenu()">
                <div class="header-hamburguer-mobile">
                    <img
                        src="assets/img/icons/hamburguer-white.svg"
                        alt="Mobile menu" />
                </div>
                <div
                    [ngStyle]="{ display: showMobileMenu ? 'flex' : 'none' }"
                    class="header-group-button-account">
                    <app-button [data]="loginButtonData"></app-button>
                    <div class="extra-small-spacing"></div>
                    <app-button [data]="registerButtonData"></app-button>
                </div>
            </div>
        }
    </div>
</header>
