import { SocialIcon } from '../social-icon.model';
import { initUser, UserModel } from './user.model';
import { SOCIAL_ICON_ENUM } from '../../enums/social-icon';
import { Operation } from '../post-section/operation.model';
import { OPERATION_TITLE_ENUM } from '@app/enums/post-section/operation_title.enum';
import { OPERATION_COLOR_ENUM } from '@app/enums/post-section/operation_color.enum';
import { OPERATION_ICON_ENUM } from '@app/enums/post-section/operation_icon.enum';

interface GeolocationModel {
    latitude: string;
    longitude: string;
}

export interface PostModel {
    distance: number;
    geolocation: GeolocationModel;
    id: string;
    icons: SocialIcon[];
    follower: boolean;
    user: UserModel;
    list: Operation[];
    loading?: boolean;
}

const initIcons: SocialIcon[] = [
    {
        name: 'heart',
        icon: SOCIAL_ICON_ENUM.heartEmpty,
        quantity: 0,
        text: true,
        url: '',
        message: '',
        click: false,
    },
    {
        name: 'whatsapp',
        icon: SOCIAL_ICON_ENUM.whatsapp,
        quantity: 0,
        text: true,
        url: '',
        message: '',
        click: false,
    },
    {
        name: 'facebook',
        icon: SOCIAL_ICON_ENUM.facebook,
        quantity: 0,
        text: true,
        url: '',
        message: '',
        click: false,
    },
    {
        name: 'santovecino',
        icon: SOCIAL_ICON_ENUM.santovecino,
        quantity: 0,
        text: true,
        url: '',
        message: '',
        click: false,
    },
];

const initList: Operation[] = [
    {
        id: 1,
        case: 3,
        title: OPERATION_TITLE_ENUM.haveIt,
        color: OPERATION_COLOR_ENUM.haveIt2,
        backgroundColor: 'bg-gradient-10',
        image: OPERATION_ICON_ENUM.haveIt,
        operation: 'haveIt',
        description: 'Aquí va lo que ofreces',
        placeholder: 'Agrega la chamba o cosa que ofreces',
        dataSource: [],
    },
    {
        id: 2,
        case: 3,
        title: OPERATION_TITLE_ENUM.wantIt,
        color: OPERATION_COLOR_ENUM.wantIt2,
        backgroundColor: 'bg-gradient-10',
        image: OPERATION_ICON_ENUM.wantIt,
        operation: 'wantIt',
        description: 'Aquí va lo que buscas o necesitas',
        placeholder: 'Agrega la chamba o cosa que necesitas',
        dataSource: [],
    },
    {
        id: 3,
        case: 3,
        title: OPERATION_TITLE_ENUM.giveYouAHand,
        color: OPERATION_COLOR_ENUM.giveYouAHand2,
        backgroundColor: 'bg-gradient-10',
        image: OPERATION_ICON_ENUM.giveYouAHand,
        operation: 'giveYouAHand',
        description: 'Aquí va lo que das sin nada a cambio',
        placeholder: 'Agrega la chamba o cosa que ofreces',
        dataSource: [],
    },
    {
        id: 4,
        case: 3,
        title: OPERATION_TITLE_ENUM.giveMeAHand,
        color: OPERATION_COLOR_ENUM.giveMeAHand2,
        backgroundColor: 'bg-gradient-10',
        image: OPERATION_ICON_ENUM.giveMeAHand,
        operation: 'giveMeAHand',
        description: 'Aquí va lo que necesitas sin dar nada a cambio',
        placeholder: 'Agrega la chamba o cosa que necesitas',
        dataSource: [],
    },
];

export const initPost: PostModel = {
    distance: 0,
    geolocation: {
        latitude: '0',
        longitude: '0',
    },
    id: '',
    icons: initIcons,
    follower: false,
    user: initUser,
    list: initList,
    loading: true,
};
