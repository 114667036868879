import { Platform } from '@angular/cdk/platform';
import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { LocalStorageService } from '@app/services/local-storage/local-storage.service';
import { NavigationService } from '@app/services/navigation/navigation.service';

export const urlSimpleGuard: CanActivateFn = (route, state) => {
    const { url, queryParams } = route;
    const localStorageService = inject(LocalStorageService);
    const navigationService = inject(NavigationService);
    const _platform = inject(Platform);

    if (!_platform.isBrowser) {
        return true;
    }

    if (queryParams['data']) {
        const { accessToken, refreshToken, data, havePost, isComplete } =
            JSON.parse(atob(queryParams['data']));

        navigationService.redirectLogin(
            refreshToken,
            accessToken,
            data,
            havePost,
            isComplete
        );

        return false;
    }

    // if (url.length == 0 && !localStorageService.getItem('video')) {
    //     navigationService.navigatePage('prehome/video');
    //     return false;
    // }

    // if (url.length == 0 && localStorageService.getItem('video')) {
    //     navigationService.navigatePage('autenticacion/login');
    //     return false;
    // }

    if (url.length == 0) {
        navigationService.navigatePage('autenticacion/bienvenida');
        return false;
    }

    return true;
};
