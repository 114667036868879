import { Platform } from '@angular/cdk/platform';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Subscription } from 'rxjs';

import { LocalStorageService } from '@app/services/local-storage/local-storage.service';
import { ShareInfoService } from '@app/services/share-info/share-info.service';
import { CookiesBannerComponent } from '../cookies-banner/cookies-banner.component';
import { FooterComponent } from '../footer/footer.component';
import { HeaderComponent } from '../header/header.component';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-header-footer-root',
    standalone: true,
    imports: [
        RouterOutlet,
        CookiesBannerComponent,
        HeaderComponent,
        FooterComponent,
        NgClass
    ],
    templateUrl: './root-header-footer.html',
    styleUrls: ['./root-header-footer.sass'],

})
export class RootHeaderFooterComponent implements OnInit, OnDestroy {
    listSubscription: Subscription[];

    // public navigationService: NavigationService = inject(NavigationService);
    private readonly localStorageService: LocalStorageService =
        inject(LocalStorageService);
    private readonly gtmService: GoogleTagManagerService = inject(
        GoogleTagManagerService
    );
    private readonly _platform: Platform = inject(Platform);
    private readonly shareInfoService: ShareInfoService =
        inject(ShareInfoService);
    private readonly router: Router = inject(Router);

    constructor() {
        this.listSubscription = [new Subscription(), new Subscription()];
    }

    ngOnInit() {
        // this.subscribeAcceptCookies();
        // if (this._platform.isBrowser) {
        //     this.gtmService.addGtmToDom();
        // }

        if (
            this.localStorageService.getItem('accept_cookie') &&
            this.localStorageService.getItem('accept_cookie', true) === '1'
        ) {
            // this.googleTagManager();
        }
    }

    ngOnDestroy() {
        this.listSubscription.forEach(itrSub => {
            itrSub.unsubscribe();
        });
    }

    private subscribeAcceptCookies() {
        if (!this._platform.isBrowser) return;
        this.listSubscription[0] =
            this.shareInfoService.acceptCookies$.subscribe(() => {
                this.googleTagManager();
            });
    }

    private googleTagManager() {
        this.router.events.forEach(item => {
            if (item instanceof NavigationEnd) {
                const gtmTag = {
                    event: 'page',
                    pageName: item.url,
                };

                this.gtmService.pushTag(gtmTag);
            }
        });
    }

    public validateChat() {
        return this.router.url.includes('chat') ? false : true;
    }
}
