import { Platform } from '@angular/cdk/platform';

import { DataPage } from '@app/models/data-page.model';

export function positionScroll(
    dataPage: DataPage,
    _platform: Platform
): boolean {
    if (typeof window === 'undefined') return false;

    if (
        _platform.isBrowser &&
        dataPage.scrollPosition != 0 &&
        dataPage.dataSource.length != 0
    ) {
        setTimeout(() => {
            window.scrollTo({
                top: dataPage.scrollPosition,
                behavior: 'smooth',
            });
        }, 10 * dataPage.dataSource.length);

        return false;
    }

    return true;
}

export function scrollReset(): void {
    if (typeof window === 'undefined') return;

    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
}
