import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

interface VersionInfo {
    message: string;
    data: {
        releaseVersion: string;
    };
}

@Injectable({
    providedIn: 'root',
})
export class CommonService {
    private readonly API_URI = environment.PROXY
        ? '/api/'
        : environment.API_URL_V1;
    private http = inject(HttpClient);

    getReleaseVersion(): Observable<VersionInfo> {
        return this.http.get<VersionInfo>(
            `${this.API_URI}common/release-version`
        );
    }
}
