@if (viewComponent) {
    <section id="cookies-banner" [@fadeInOut]>
        <div class="row">
            <div
                class="col-desktop-45 col-tablet-40 col-phablet-40 col-phone-40 d-flex cookies-images"
            >
                <div class="cookies-images-container">
                    <img
                        class="logo"
                        [src]="santoVecinoLogoWhite.url"
                        [alt]="santoVecinoLogoWhite.alt"
                    />
                    <img
                        class="vicente"
                        [src]="vicenteCookies.url"
                        [alt]="vicenteCookies.alt"
                    />
                </div>
            </div>
            <div
                class="col-desktop-55 col-tablet-60 col-phablet-70 col-phone-72 text"
            >
                <h1>{{ cookieModalMessage[0] }}</h1>
                <h5>
                    {{ cookieModalMessage[1]
                    }}<a [routerLink]="['privacy/cookies']">{{
                        cookieModalMessage[2]
                    }}</a>
                    {{ cookieModalMessage[3] }}
                </h5>
                <h5>{{ cookieModalMessage[4] }}</h5>
                <div class="d-flex cookies-button-container">
                    <app-button
                        (click)="showModalCookies()"
                        [data]="rejectButtonData"
                    ></app-button>
                    <div class="extra-small-spacing"></div>
                    <app-button
                        (notify)="manageCookie('add')"
                        [data]="acceptButtonData"
                    ></app-button>
                </div>
            </div>
        </div>
    </section>
}
