import { Platform } from '@angular/cdk/platform';
import {
    HttpErrorResponse,
    HttpHandlerFn,
    HttpInterceptorFn,
    HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { jwtDecode, JwtPayload } from 'jwt-decode';

import { LocalStorageService } from '@app/services/local-storage/local-storage.service';
import { AuthenticationService } from '@app/providers/authentication/authentication.service';
import { Observable, switchMap } from 'rxjs';
import { LoginObservable } from '../../observables/login.observable';

/**
 * Verifica si un token JWT es válido.
 * @param {string} token - El token JWT a validar.
 * @returns {boolean} - Devuelve true si el token es válido, false en caso contrario.
 */
const isTokenValid = (token: string): boolean => {
    try {
        const currentToken = jwtDecode<JwtPayload>(token);
        if (currentToken.exp) {
            const today: Date = new Date();
            const tokenDate = new Date(0);
            tokenDate.setUTCSeconds(currentToken.exp);
            return tokenDate.getTime() > today.getTime();
        }
        return false;
    } catch (error) {
        return false;
    }
};

/**
 * Actualiza los tokens si el token de actualización es válido, de lo contrario, lanza un error.
 * @param {HttpRequest<any>} req - La solicitud HTTP.
 * @param {HttpHandlerFn} next - La función de manejo HTTP.
 * @param {LocalStorageService} localStorageService - El servicio de almacenamiento local.
 * @param {AuthenticationService} authenticationService - El servicio de autenticación.
 * @param {LoginObservable} loginObservable - El observable de inicio de sesión.
 * @returns {Observable<any>} - Un observable que emite el resultado de la solicitud HTTP.
 */

const updateTokens = (
    req: HttpRequest<any>,
    next: HttpHandlerFn,
    localStorageService: LocalStorageService,
    authenticationService: AuthenticationService,
    loginObservable: LoginObservable
): Observable<any> => {
    const refreshToken = localStorageService.getItem('refreshToken', true);
    const is_validToken: boolean = isTokenValid(refreshToken);

    if (!is_validToken) {
        loginObservable.updateData(null);
        throw new HttpErrorResponse({ error: 'Tokens vencidos' });
    }

    if (refreshToken && is_validToken) {
        return authenticationService.refreshToken(refreshToken).pipe(
            switchMap(() => {
                const token = localStorageService.getItem('token', true);
                const headers = req.headers.get('Cache-Control')
                    ? req.headers.set('Authorization', `Bearer ${token}`)
                    : req.headers
                          .set('Content-Type', 'application/json')
                          .set('Accept', 'application/json')
                          .set('Authorization', `Bearer ${token}`);

                return next(req.clone({ headers }));
            })
        );
    }
    return next(req);
};

/**
 * Intercepta las solicitudes HTTP para agregar una cabecera de autorización con un token JWT.
 * @param {HttpRequest<any>} req - La solicitud HTTP.
 * @param {HttpHandlerFn} next - La función de manejo HTTP.
 * @returns {Observable<any>} - Un observable que emite el resultado de la solicitud HTTP.
 */
export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
    const _platform = inject(Platform);

    // Verifica si la plataforma es un navegador y si la URL incluye 'santovecino' pero no 'refresh-tokens'
    if (
        !_platform.isBrowser ||
        !req.url.includes('santovecino') ||
        req.url.includes('refresh-tokens')
    )
        return next(req);

    const localStorageService = inject(LocalStorageService);
    const authenticationService = inject(AuthenticationService);
    const loginObservable = inject(LoginObservable);

    const token = localStorageService.getItem('token', true);

    if (token) {
        // Verifica si el token no es válido
        if (!isTokenValid(token)) {
            return updateTokens(
                req,
                next,
                localStorageService,
                authenticationService,
                loginObservable
            );
        }
        // Establece los encabezados de la solicitud HTTP
        const headers = req.headers.get('Cache-Control')
            ? req.headers.set('Authorization', `Bearer ${token}`)
            : req.headers
                  .set('Content-Type', 'application/json')
                  .set('Accept', 'application/json')
                  .set('Authorization', `Bearer ${token}`);
        return next(req.clone({ headers }));
    }

    return next(req);
};
