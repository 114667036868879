<div class="small-container search-post-box">
    <app-input-search
        [placeholder]="'Busca en Santo Vecino'"
        [backgroundColor]="'#65328F'"
        [color]="'#fff'"
        [centered]="true"
        [rounded]="true"
        (viewSearch)="search($event)" />

</div>
<div class="small-container m-auto carrousel-category-home">
    <app-categories-carousel />
</div>
<main class="small-container m-auto">
    @for (post of dataPage?.dataSource; track $index) {
        @defer {
            <app-publication-card
                [post]="post"
                [currentAccount]="currentAccount" />
        } @placeholder {
            <app-publication-card
                [post]="post"
                [currentAccount]="currentAccount" />
        } @loading {
            <app-publication-card-skeleton />
        }
    }
    <div #anchor></div>
</main>
