import { ErrorHandler, Injectable } from '@angular/core';
import { ShareInfoService } from '../share-info/share-info.service';

@Injectable({
    providedIn: 'root',
})
export class CustomErrorHandlerService implements ErrorHandler {
    handleError(error: any): void {
        const shareInfoService: ShareInfoService = new ShareInfoService();

        shareInfoService.snackVicente$.emit({
            message: 'Ha ocurrido un error inesperado',
            type: 'error',
        });

        console.warn('Custom error handler: ', error);
    }
}
