import { animate, style, transition, trigger } from '@angular/animations';
import { Platform } from '@angular/cdk/platform';
import { NgStyle } from '@angular/common';
import {
    Component,
    HostBinding,
    inject,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { ShareInfoService } from '@app/services/share-info/share-info.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-snack-bar-vicente',
    standalone: true,
    imports: [NgStyle],
    templateUrl: './snack-bar-vicente.component.html',
    styleUrl: './snack-bar-vicente.component.sass',
    animations: [
        trigger('slideInOut', [
            transition(':enter', [
                style({ top: '-2rem' }),
                animate('0.3s ease-in-out', style({ top: '2rem' })),
            ]),
            transition(':leave', [
                animate('0.3s ease-in-out', style({ top: '-2rem' })),
            ]),
        ]),
    ],
})
export class SnackBarVicenteComponent implements OnInit, OnDestroy {
    private readonly shareInfoService = inject(ShareInfoService);
    private readonly _platform = inject(Platform);

    @HostBinding('@slideInOut') visible: boolean = false;

    classStyle: string = 'container_snack';
    message: string = '';
    img: string = 'assets/chat/icons/vicente-deal-done.svg';
    listSubscription: Subscription[] = [];

    ngOnInit(): void {
        if (!this._platform.isBrowser) return;

        this.subscriptionSnackVicente();
    }

    ngOnDestroy() {
        this.listSubscription.forEach(itrSub => {
            itrSub.unsubscribe();
        });
    }

    private subscriptionSnackVicente(): void {
        this.shareInfoService.snackVicente$.subscribe(
            (res: { type: string; message: string }) => {
                this.message = res.message;
                this.visible = true;

                if (res.type === 'done') {
                    this.classStyle = 'container_snack bg-gradient-done';
                    this.img = 'assets/chat/icons/vicente-deal-done.svg';
                } else if (res.type === 'error') {
                    this.classStyle = 'container_snack bg-gradient-error';
                    this.img = 'assets/chat/icons/vicente-deal-done.svg';
                }

                setTimeout(() => {
                    this.visible = false;
                    this.message = '';
                }, 3000);
            }
        );
    }
}
