@if (notificationVisible) {
    <div
        class="notification"
        [@slideInOut]
        (mouseenter)="onMouseEnter()"
        (mouseleave)="onMouseLeave()">
        <div class="position-relative container-notification">
            <div class="close" (click)="closeNotification()">
                <img class="close-icon" [src]="closeIcon" alt="close icon" />
            </div>
            <div class="d-flex title-container">
                <img class="bell-icon" [src]="bellIcon" alt="bell icon" />
                <div class="extra-small-spacing"></div>
                <span class="notification-title">Notificaciones</span>
            </div>
            <span class="title" *ngIf="notificationData.title">{{
                notificationData.title
            }}</span>
            <span
                class="description"
                *ngIf="notificationData.description"
                [innerHTML]="notificationData.description"></span>
            <div
                *ngIf="notificationData.url"
                class="notification-options d-flex justify-content-center">
                <app-button
                    (click)="redirect(notificationData.url)"
                    [data]="{
                        title: 'Conversar',
                        style: 'secondary',
                        type: 'emit'
                    }"></app-button>
            </div>
            <div
                *ngIf="notificationData.time"
                class="notification-hour align-items-center justify-content-center d-flex">
                <div class="col container-line"><div class="line"></div></div>
                <span class="d-block">{{ notificationData.time }}</span>
            </div>
        </div>
    </div>
}
