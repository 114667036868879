import { Platform } from '@angular/cdk/platform';
import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { LocalStorageService } from '@app/services/local-storage/local-storage.service';
import { environment } from '@environments/environment';

export const securityDevQaGuard: CanActivateFn = (route, state) => {
    const _platform = inject(Platform);
    const localStorageService = inject(LocalStorageService);

    if (!_platform.isBrowser) return true;

    if (environment.production) return true;

    return true;

    // if (
    //     window.location.hostname === 'localhost' ||
    //     window.location.hostname === '127.0.0.1'
    // )
    //     return true;

    // if (
    //     localStorageService.getItem('sv-dev-qa', true) ===
    //     environment.passwordDevQa
    // )
    //     return true;

    // const password = prompt('Ingresa la contraseña:');
    // if (password === environment.passwordDevQa && password) {
    //     localStorageService.setItem('sv-dev-qa', password);
    //     return true;
    // }

    // return false;
};
