import { Platform } from '@angular/cdk/platform';
import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { FilterDataAlgoliaSearch } from '@app/functions/data-algolia-search.function';
import { positionScroll } from '@app/functions/scroll-position.function';
import { Account } from '@app/models/authentication/account.model';
import { ResponseSearchInput } from '@app/models/category/responseSearchInput.model';
import { DataPage } from '@app/models/data-page.model';
import { PostModel } from '@app/models/post-box/post.model';
import { Operation } from '@app/models/post-section/operation.model';
import { SocialIcon } from '@app/models/social-icon.model';
import { CategoriesCarouselComponent } from '@app/modules/home/categories-carousel/categories-carousel.component';
import { InputSearchComponent } from '@app/modules/search/input-search/input-search.component';
import { LoginObservable } from '@app/observables/login.observable';
import { ScrollObservable } from '@app/observables/scroll.observable';
import { DataPageService } from '@app/services/data-page/data-page.service';
import { NavigationService } from '@app/services/navigation/navigation.service';
import { ScrollHomeService } from '@app/services/scroll-home/scroll-home.service';
import { SeoService } from '@app/services/seo/seo.service';
import { ShareInfoService } from '@app/services/share-info/share-info.service';
import { SocialSharedService } from '@app/services/social-shared/social-shared.service';
import { environment } from '@environments/environment';
import { Subscription } from 'rxjs';
import { PublicationCardSkeletonComponent } from '../publication-card-skeleton/publication-card-skeleton.component';
import { PublicationCardComponent } from '../publication-card/publication-card.component';
import {
    ResponseAllPostBoxNew,
    ResponseAllPostBoxPublic,
} from '@app/models/post-box/responsePostBox.model';
import { PostService } from '@app/providers/post/post.service';
import { viewError } from '@app/functions/view-error-user.function';
import { initialDataP } from '@app/functions/structure-data-home.function';
import { MetaDataSeo } from '@app/arrays/meta-data-seo.array';

@Component({
    selector: 'app-home',
    standalone: true,
    imports: [
        CategoriesCarouselComponent,
        PublicationCardComponent,
        PublicationCardSkeletonComponent,
        InputSearchComponent,
    ],
    templateUrl: './home.component.html',
    styleUrl: './home.component.sass',
})
export class HomeComponent {
    @ViewChild('anchor', { static: true }) anchor: ElementRef<HTMLElement>;
    observer: IntersectionObserver;
    public dataPage: DataPage;
    public currentAccount: Account | null = null;
    private loading = false;

    private currentAccountSubscription = new Subscription();
    private scrollSubscribe = new Subscription();

    private readonly _platform = inject(Platform);
    private readonly navigationService = inject(NavigationService);
    private readonly seoService = inject(SeoService);
    private readonly loginObservable = inject(LoginObservable);
    private readonly postService = inject(PostService);
    private readonly dataPageService = inject(DataPageService);
    private readonly socialSharedService = inject(SocialSharedService);
    private readonly scrollHomeService = inject(ScrollHomeService);
    private readonly scrollObservable = inject(ScrollObservable);
    private readonly shareInfoService = inject(ShareInfoService);

    constructor() {
        this.dataPage = {
            dataSource: [],
            scrollPosition: 0,
            sizeContainer: '1000px',
            scrollEnd: false,
            skip: 0,
            search: '',
            limit: 10,
        };
    }

    ngOnInit(): void {
        this.dataPage.skip = this.scrollHomeService.page;
        this.dataPage.dataSource = this.scrollHomeService.items;
        this.dataPage.scrollPosition = this.scrollHomeService.scrollPosition;

        this.setMetaTags();
        this.getCurrentAccount();
        this.subscriptionScrollPosition();

        if (this.dataPage.dataSource.length === 0) {
            this.loadMore();
        } else {
            positionScroll(this.dataPage, this._platform);
        }
    }

    ngAfterViewInit() {
        if (!this._platform.isBrowser) {
            return;
        }
        this.observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                this.loadMore();
            }
        });
        this.observer.observe(this.anchor.nativeElement);
    }

    ngOnDestroy(): void {
        this.currentAccountSubscription.unsubscribe();
        this.scrollSubscribe.unsubscribe();

        this.scrollHomeService.items = this.dataPage.dataSource;
        this.scrollHomeService.page = this.dataPage.skip;
    }

    // Obtener la informacion del usuario si tiene sesion iniciada
    private getCurrentAccount(): void {
        this.currentAccountSubscription = this.loginObservable.data$.subscribe(
            (res: Account | null) => {
                this.currentAccount = res;
            }
        );
    }

    // Obtener los post
    private loadMore(): void {
        if (this.loading) {
            return;
        }
        this.loading = true;
        (this.currentAccount
            ? this.postService.allPostBoxNew({
                  skip: this.dataPage.skip,
                  limit: this.dataPage.limit,
                  needFollowers: true,
              })
            : this.postService.allPostBoxPublic({
                  skip: this.dataPage.skip,
                  limit: this.dataPage.limit,
              })
        ).subscribe(
            (res: ResponseAllPostBoxPublic | ResponseAllPostBoxNew) => {
                // const { data, followers, listUseBlocked } = res;
                // Settear la informacion de la pagina, cómo los post
                // y los nuevos filtros cuando se carga nueva informacion

                this.dataPage = this.dataPageService.dataSourceFill(
                    this.structureData(
                        res.data,
                        'followers' in res ? res.followers : [],
                        'listUseBlocked' in res
                            ? res.listUseBlocked.blockedUserId
                            : []
                    ),
                    { ...this.dataPage },
                    this.dataPage.limit
                );

                this.loading = false;
            },
            ({ code }) => {
                this.loading = false;
                viewError('error', code, this.shareInfoService);
            }
        );
    }

    structureData(
        data: any[],
        follower: string[],
        listUseBlocked: string[]
    ): any {
        if (!data) {
            return;
        }
        const newData: PostModel[] = [];
        data.forEach((itrData: any) => {
            if (
                listUseBlocked?.includes(itrData.postBox.informationUser.userId)
            ) {
                return;
            }
            const {
                postBox: {
                    informationPostBox,
                    informationUser,
                    publications,
                    distance,
                },
                likes,
            } = itrData;
            const {
                design: { pattern, color },
                statsPostbox,
                postBoxId,
            } = informationPostBox;

            const listOperation: Operation[] = initialDataP(pattern, color);

            if (publications.length == 0) return;

            publications.forEach((itrPublication: any) => {
                listOperation[
                    itrPublication.typePublication - 1
                ].dataSource.push({
                    ...itrPublication,
                });
            });

            const message: string = `¡Hola!, te invito a que visites el perfil de ${informationUser.info.fullName
                .toLowerCase()
                .replace(/\b\w/g, (l: string) =>
                    l.toUpperCase()
                )} en Santo Vecino !`;

            let icons: SocialIcon[] = this.socialSharedService.getDataChange([
                {
                    name: 'heart',
                    quantity: statsPostbox.likes,
                },
                {
                    name: 'whatsapp',
                    url: `https://wa.me?text=${environment.URL_SANTO_VECINO}/profile/${informationUser.userId}`,
                    message,
                },
                {
                    name: 'facebook',
                    url: `https://www.facebook.com/sharer/sharer.php?u=${environment.URL_SANTO_VECINO}/profile/${informationUser.userId}`,
                    message,
                },
                {
                    name: 'santovecino',
                    quantity: statsPostbox.numberShares,
                },
            ]);

            if (this.currentAccount) {
                icons = this.socialSharedService.checkLikes(likes, icons);
            }

            newData.push({
                distance,
                geolocation: { ...informationUser.address },
                id: postBoxId,
                icons,
                follower: this.socialSharedService.viewFollower(
                    follower,
                    informationUser
                ),
                user: informationUser,
                list: listOperation,
                loading: true,
            });
        });

        return newData;
    }

    private setMetaTags(): void {
        this.seoService.setTitle(MetaDataSeo['Home'].title);
        this.seoService.setDescription(MetaDataSeo['Home'].description);
        this.seoService.setIndexingFollower(true);
        this.seoService.setCanonicalURL();
    }

    search({ list, word }: ResponseSearchInput): void {
        list = FilterDataAlgoliaSearch(list);

        if (list.length !== 0) {
            if (list[0].type === 'publication') {
                if (list.length === 1) {
                    this.navigationService.navigatePage(
                        `publicacion/${list[0]._id}`
                    );
                    return;
                }

                this.navigationService.navigatePage(`/resultados/${word}`);
            } else if (list[0].type === 'profile') {
                if (list.length === 1) {
                    this.navigationService.navigatePage(
                        `profile/${list[0]._id}`
                    );
                    return;
                }
                return;
            } else if (list[0].type === 'subcategory') {
                return;
            } else if (list[0].type === 'category' && list.length === 1) {
                return;
            }
        }

        if (list.length === 0 && word != '') {
            this.shareInfoService.snackVicente$.emit({
                message: 'NO SE HA ENCONTRADO COINCIDENCIAS',
                type: 'error',
            });
        }
    }

    responseBar(res: string): void {
        if (!this._platform.isBrowser) return;

        this.navigationService.navigatePage(`resultados/${res.trim()}`);
    }

    private subscriptionScrollPosition(): void {
        if (!this._platform.isBrowser) return;

        this.scrollSubscribe = this.scrollObservable.data$.subscribe(
            (res: number) => {
                if (res == 0) return;

                this.dataPage.scrollPosition = res;
            }
        );
    }
}
