import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { Platform } from '@angular/cdk/platform';
import { NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';

import { Dialog } from '@angular/cdk/dialog';
import { ButtonData, ImageData } from '@app/models/cookies.model';
import { CookiesBannerModalComponent } from '@app/modules/modals/cookies-banner-modal/cookies-banner-modal.component';
import { LocalStorageService } from '@app/services/local-storage/local-storage.service';
import { ShareInfoService } from '@app/services/share-info/share-info.service';
import { environment } from '@environments/environment';
import { ButtonComponent } from '../button/button.component';

@Component({
    selector: 'app-cookies-banner',
    standalone: true,
    imports: [RouterLink, NgIf, ButtonComponent],
    templateUrl: './cookies-banner.component.html',
    styleUrl: './cookies-banner.component.sass',
    animations: [
        trigger('fadeInOut', [
            state(
                'void',
                style({
                    opacity: 0,
                })
            ),
            transition('void <=> *', animate(500)),
        ]),
    ],
})
export class CookiesBannerComponent {
    viewComponent: boolean = false;

    file: string = `${environment.CATEGORY_FILE}attachments/`;
    vicenteCookies: ImageData = {
        url: `assets/img/vicente_cookies.gif`,
        alt: 'Vicente política de cookies Santo Vecino',
    };
    santoVecinoLogoWhite: ImageData = {
        url: `${this.file}santo-vecino-logo-white.png`,
        alt: 'Santo Vecino logo blanco',
    };

    cookieModalMessage: string[] = [
        '¿Una galletita, joven?',
        'Checa nuestra',
        ' política de cookies ',
        'para mejorar tu experiencia.',
        'La información la usaremos para ofrecerte una experiencia dentro del sitio más adaptada para ti.',
    ];

    commonButtonProperties = {
        customClass: 'w-100 big-btn-font cookies-button',
        type: 'emit',
        rounded: false,
        uppercase: false,
        disabled: false,
    };

    rejectButtonData: ButtonData = {
        title: 'Personalizar Cookies',
        style: 'cookieSetting',
        minWidth: '80px',
        ...this.commonButtonProperties,
    };

    acceptButtonData: ButtonData = {
        title: 'Aceptar Cookies',
        style: 'secondary',
        minWidth: '80px',
        ...this.commonButtonProperties,
    };

    private readonly shareInfoService = inject(ShareInfoService);
    private readonly localStorageService = inject(LocalStorageService);
    private readonly dialog = inject(Dialog);
    private readonly _platform = inject(Platform);

    constructor() {
        this.viewComponent =
            this._platform.isBrowser &&
            (!this.localStorageService.getItem('accept_cookie') ||
                this.localStorageService.getItem('accept_cookie', true) ===
                    '0');
    }

    manageCookie(operation: string): void {
        this.viewComponent = false;

        if (operation == 'add') {
            this.shareInfoService.acceptCookies$.emit();
            this.localStorageService.setItem('accept_cookie', '1');
        } else {
            this.localStorageService.setItem('accept_cookie', '0');
        }
    }

    showModalCookies(): void {
        const dialogRef = this.dialog.open(CookiesBannerModalComponent, {});

        dialogRef.closed.subscribe((response: any) => {
            if (response != undefined && response != null && response) {
                this.manageCookie(response[1].rejected ? 'remove' : 'add');
            }
        });
    }
}
