import { DialogRef } from '@angular/cdk/dialog';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ButtonComponent } from '@app/components/button/button.component';
import { ButtonData, CookiesCardModalData } from '@app/models/cookies.model';

@Component({
    selector: 'app-cookies-banner-modal',
    standalone: true,
    imports: [FormsModule, ButtonComponent],
    templateUrl: './cookies-banner-modal.component.html',
    styleUrl: './cookies-banner-modal.component.sass',
})
export class CookiesBannerModalComponent {
    public bottomModalMessage: string =
        'Para modificar tus preferencias de cookies, asegúrate de acceder a tu cuenta antes de realizar cambios. Si ajustas la configuración sin iniciar sesión, no podremos identificarte en otros dispositivos y aplicar tus preferencias.';
    public notificationMessage: string =
        'Utilizamos cookies cómo tecnología para comprender tu interacción con nuestro sitio, facilitando así el uso de tu cuenta y personalizando la publicidad según tus intereses.';
    public exploreMessage: string =
        'Explora información adicional acerca de las cookies en Santo Vecino.';

    public cardModalsData: CookiesCardModalData[] = [
        {
            title: 'Cookies esenciales',
            hasToggleButton: false,
            description:
                'Estas cookies cumplen funciones clave al reconocerte al ingresar, guardar tus preferencias de configuración y asegurar la protección de tu cuenta. No es posible desactivarlas, ya que son indispensables para el correcto funcionamiento de nuestro sitio.',
        },
        {
            title: 'Cookies analíticas',
            hasToggleButton: true,
            toggleName: 'analytics',
            description:
                'Estas cookies posibilitan el análisis de tu navegación en el sitio. Al desactivarlas, nos vemos imposibilitados de realizar análisis que contribuyan a mejorar tu experiencia en el sitio.',
            rejected: false,
        },
        {
            title: 'Cookies de publicidad personalizada',
            description:
                'Estas cookies nos ayudan a comprender tus preferencias para presentarte anuncios adaptados a tus intereses. Desactivarlas eliminaría una de las fuentes de información que utilizamos para personalizar los anuncios.',
            rejected: false,
            toggleName: 'personalized-ads',
            hasToggleButton: true,
        },
        {
            title: 'Cookies de rendimiento',
            description:
                'Estas cookies posibilitan la optimización de diversas funciones en nuestro sitio. Desactivarlas podría ocasionar cambios en la velocidad o resolución de ciertas características.',
            rejected: false,
            toggleName: 'performance',
            hasToggleButton: true,
        },
        {
            title: 'Cookies funcionales',
            hasToggleButton: true,
            toggleName: 'functional',
            rejected: false,
            description:
                'Estas cookies posibilitan el mantenimiento del correcto funcionamiento de nuestro sitio. Desactivarlas podría ocasionar cambios en tu experiencia de navegación.',
        },
    ];

    public title: string = 'Configurar cookies';

    saveButtonData: ButtonData = {
        title: 'Guardar',
        style: 'primary',
        customClass: 'big-btn-font cookies-button',
        type: 'emit',
        rounded: false,
        uppercase: true,
        disabled: false,
    };

    constructor(public dialogRef: DialogRef<any>) {}

    saveModalData(): void {
        this.dialogRef.close(this.cardModalsData);
    }
}
