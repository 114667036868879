import { Platform } from '@angular/cdk/platform';
import {
    HttpEvent,
    HttpHandlerFn,
    HttpInterceptorFn,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable, tap } from 'rxjs';

import { ShareInfoService } from '@app/services/share-info/share-info.service';

/**
 * Interceptor HTTP que muestra un spinner durante las solicitudes HTTP.
 *
 * @param {HttpRequest<any>} req - Solicitud HTTP entrante.
 * @param {HttpHandlerFn} next - Manejador HTTP.
 * @returns {Observable<HttpEvent<unknown>>} - Un observable que emite el resultado de la solicitud HTTP.
 */

export const spinnerInterceptor: HttpInterceptorFn = (
    req: HttpRequest<any>,
    next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
    /**
     * Servicio utilizado para compartir información y mostrar un spinner en la interfaz de usuario.
     * @type {ShareInfoService}
     */

    const shareInfoService: ShareInfoService = inject(ShareInfoService);

    /**
     * Servicio utilizado para determinar si la aplicación se está ejecutando en un navegador.
     * @type {Platform}
     */
    const _platform: Platform = inject(Platform);

    /**
     * URL de la solicitud HTTP.
     * @type {string}
     */
    const url: string = req.url;

    /**
     * Verifica si la aplicación no se está ejecutando en un navegador, si no hay conexión a internet,
     * o si la URL no incluye 'santovecino'. Si alguna de estas condiciones es verdadera, la solicitud se pasa al siguiente manejador sin realizar ningún procesamiento adicional.
     */
    if (
        !_platform.isBrowser ||
        !window.navigator.onLine ||
        !url.includes('santovecino')
    )
        return next(req);

    /**
     * Si la solicitud no tiene el encabezado 'notSpinner', se muestra el spinner.
     */
    if (!req.headers.get('notSpinner')) {
        shareInfoService.spinner$.emit(true);
    }

    return next(req).pipe(
        tap(
            (event: HttpEvent<any>) => {
                /**
                 * Si el evento es una respuesta HTTP, se oculta el spinner después de un breve retraso.
                 */
                if (!(event instanceof HttpResponse)) {
                    return;
                }
                setTimeout(() => {
                    shareInfoService.spinner$.emit(false);
                }, 100);
            },
            () => {
                /**
                 * En caso de error, se oculta el spinner después de un breve retraso.
                 */
                setTimeout(() => {
                    shareInfoService.spinner$.emit(false);
                }, 100);
            }
        )
    );
};
